<template>
  <VaNavbar class="app-layout-navbar py-2 px-0">
    <template #left>
      <div class="left">
        <Transition v-if="isMobile" name="icon-fade" mode="out-in">
          <VaIcon
            color="primary"
            :name="isSidebarMinimized ? 'menu' : 'close'"
            size="24px"
            style="margin-top: 3px"
            @click="isSidebarMinimized = !isSidebarMinimized"
          />
        </Transition>
        <RouterLink to="/" aria-label="Visit home page">
          <VuesticLogo />
        </RouterLink>
      </div>
    </template>
    <template #center>
      <div v-if="alerts.length > 0" class="alert-ribbon">
        <Vue3Marquee :duration="20" :pause-on-hover="true" :space="10">
          <span v-for="(alert, index) in alerts" :key="index" class="text-red-500 font-bold text-xl alert-message">
            {{ alert.message }}
          </span>
        </Vue3Marquee>
      </div>
    </template>
    <template #right>
      <AppNavbarActions class="app-navbar__actions" :is-mobile="isMobile" />
    </template>
  </VaNavbar>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useGlobalStore } from '../../stores/global-store'
import AppNavbarActions from './components/AppNavbarActions.vue'
import VuesticLogo from '../VuesticLogo.vue'
import { inject, onMounted, ref } from 'vue'
import { Vue3Marquee } from 'vue3-marquee'

const Echo: any = inject('Echo')
const alerts = ref<{ message: string; timestamp: number; id: number; status: string }[]>([])

defineProps({
  isMobile: { type: Boolean, default: false },
})

const GlobalStore = useGlobalStore()

const { isSidebarMinimized } = storeToRefs(GlobalStore)

function addAlert(message: string, id: number, status: string) {
  console.log('add alert e dhuksi')

  const alert = {
    message: message,
    timestamp: Date.now(),
    id: id,
    status: status,
  }
  alerts.value.push(alert)
  localStorage.setItem('alerts', JSON.stringify(alerts.value))
}

function removeResolvedAlert(id: number) {
  alerts.value = alerts.value.filter((alert) => alert.id !== id && alert.status !== 'resolved')
  localStorage.setItem('alerts', JSON.stringify(alerts.value))
}

onMounted(() => {
  console.log('mounted a dhuksi')
  const storedAlerts = JSON.parse(localStorage.getItem('alerts') || '[]')
  alerts.value = storedAlerts

  console.log('alerts', alerts.value)

  Echo.channel('partner.sos.alert').stopListening('SpSosAlertEvent')
  Echo.channel('partner.sos.alert').listen('SpSosAlertEvent', (event: any) => {
    if (event.event_status == false) {
      removeResolvedAlert(event.event_id)
    } else {
      addAlert(event.description, event.event_id, event.status)
    }
  })
})
</script>

<style lang="scss" scoped>
.va-navbar {
  z-index: 2;

  @media screen and (max-width: 950px) {
    .left {
      width: 100%;
    }

    .app-navbar__actions {
      display: flex;
      justify-content: space-between;
    }
  }
}

.left {
  display: flex;
  align-items: center;
  margin-left: 1rem;

  & > * {
    margin-right: 1rem;
  }

  & > *:last-child {
    margin-right: 0;
  }
}

.icon-fade-enter-active,
.icon-fade-leave-active {
  transition: transform 0.5s ease;
}

.icon-fade-enter,
.icon-fade-leave-to {
  transform: scale(0.5);
}
.alert-message {
  margin-right: 50px;
}
</style>
